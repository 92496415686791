import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby';
import './PostList.scss';
import RenderContent from '../../RenderContent';
import Pagination from '../Pagination';
import { Image } from '../../Image';
import { FaChevronRight } from 'react-icons/fa';
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categoryFilter, pathPrefix, pathSuffix = '', siteMetadata, showAuthor } = this.props
    return (
      <section className="post-list">
        <PostListNavigation pathPrefix={pathPrefix} categoryFilter={categoryFilter} />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts && posts.map((post, inedx) => post.node.slug !== 'gatsby' &&
                  <PostListItem key={inedx} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
                )}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={`/${pathPrefix}/${pathSuffix}`} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
    }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    featured_image_url {
      source_url
    }
  }
`
